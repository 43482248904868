import React, { useState, useEffect } from 'react'
import './home.css';
import logo from './kurb.jpeg';

const Home = () => {
  var [loading, setLoading] = useState(true)
  var [airFlow, setAirFlow] = useState(1000)
  var [diameter, setDiameter] = useState(14.42)
  var [velocity, setVelocity] = useState(0);
  var [pressure, setPressure] = useState(0.8);
  var [rectPressure, setRectPressure] = useState(0)
  var [size, setSize] = useState(0);
  var [width, setWidth] = useState(5);
  var [height, setHeight] = useState(0);
  var [method, setMethod] = useState("diameter");
  var [material, setMaterial] = useState(1);
  var [calculation, setCalculation] = useState("friction");
  var [num, setNum] = useState(144)
  var [rough, setRough] = useState(144);
  var [roundVelocity, setRoundVelocity] = useState(3000);
  var [rectangularVelocity, setrectangularVelocity] = useState(3000);
  var [squareArea, setSquareArea] = useState();
  

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 5000)
    
  }, [])




  const ductulate = async () => {
        class Duct {
          constructor(air_flow=null, pressure_loss=null, velocity=null, diameter=null, width=null, height=null, rect_velocity, rect_pressure_loss=null) {
              this.air_flow = air_flow;
              this.pressure_loss = pressure_loss;
              this.velocity = velocity;
              this.diameter = diameter;
              this.width = width;
              this.height = height;
              this.rect_velocity = rect_velocity;
              this.rect_pressure_loss = rect_pressure_loss

              console.log(width)
              console.log(height)

              if (width && height && !diameter) {
                  this.diameter = 1.3 * Math.pow((width*height), 0.625) / Math.pow((width+height), 0.25);
              }
              
              this.calculate_properties();
              this.rectangular_dimensions = this.rectangular_dimensions();
          }
          
          area() {
              
              return Math.PI * Math.pow((0.5*this.diameter), 2);
          }


          rectArea() {
            
            return this.width * this.height;
            
          }
          
          rectangular_dimensions(min_size=3, max_size=100, increment=1) {
              let dimensions = {};
              let width = min_size;
              let height = 0;
              
              let maxIterations = 1000;
              let iterations = 0;
              
              while(iterations < maxIterations) {
                  if (1.3 * Math.pow((max_size*width), .625) / Math.pow((max_size+width), .25) >= this.diameter) {
                      let innerIterations = 0;
                      
                      while (1.3 * Math.pow((height*width), .625) / Math.pow((height+width), .25) < this.diameter && innerIterations < maxIterations) {
                          height += 1;
                          innerIterations++;
                      }
                      
                      innerIterations = 0;
                      
                      while (1.3 * Math.pow((height*width), .625) / Math.pow((height+width), .25) > this.diameter && innerIterations < maxIterations) {
                          height -= 0.01;
                          innerIterations++;
                      }
              
                      height = Math.round(height*10)/10;
                      if (width > height) {
                          break;
                      }
                      if (height <= max_size) {
                          dimensions[width] = height;
                      }
                  }
              
                  width += increment;
                  iterations++;
              }
              
              if(iterations >= maxIterations) {
                  console.warn("Loop exited due to reaching max iterations!");
              }
              
              for (let width in dimensions) {
                  let height = dimensions[width];
                  dimensions[width] = Number.isInteger(height) ? height : parseFloat(height.toFixed(1));
              }
              return dimensions;
          }
          
          calculate_properties() {
              if (this.air_flow && this.pressure_loss) {
                  this.diameter = Math.pow((0.109136*Math.pow(this.air_flow,1.9)/this.pressure_loss), (1/5.02));
                  this.velocity = this.air_flow/(this.area()/rough);
      

                  
                  return;
              }
              if (this.air_flow && this.velocity) {
                  this.diameter = Math.pow((((576*this.air_flow)/this.velocity)/Math.PI), 0.5);
                  this.pressure_loss = 0.109136*Math.pow(this.air_flow, 1.9)/(Math.pow(this.diameter, 5.02));
                  this.rect_pressure_loss = Math.pow(((this.pressure_loss * Math.pow(1/(this.width/0.65 * (this.width * this.height) / (this.width + this.height)), 5.02))/0.059136), (1/1.9));

                  this.rect_velocity = this.velocity
                  return;
              }
              if (this.air_flow && this.diameter) {
                  this.pressure_loss = 0.109136*Math.pow(this.air_flow,1.9)/Math.pow(this.diameter,5.02);
                  this.rect_pressure_loss = Math.pow(((this.pressure_loss * Math.pow(1/(this.width/0.65 * (this.width * this.height) / (this.width + this.height)), 5.02))/0.059136), (1/1.9));

                  this.velocity = this.air_flow/(this.area()/rough);
                  if (this.air_flow >= 0) {
                      this.rect_velocity = this.air_flow/(this.rectArea()/139);
                  }
      
                  if (this.air_flow >= 1500) {
                      this.rect_velocity = this.air_flow/(this.rectArea()/139.5);
                  }
      
                  if (this.air_flow >= 2000) {
                    this.rect_velocity = this.air_flow/(this.rectArea()/140);
                  }
      
                  if (this.air_flow >= 2500) {
                    this.rect_velocity = this.air_flow/(this.rectArea()/146);
                  }
      
                  if (this.air_flow >= 3000) {
                    this.rect_velocity = this.air_flow/(this.rectArea()/146);
                  }
                  return;
              }
              if (this.pressure_loss && this.diameter) {
                  this.air_flow = Math.pow(((this.pressure_loss * Math.pow(this.diameter, 5.02))/0.109136), (1/1.9));
                  this.velocity = this.air_flow/(this.area()/rough);
                  if (this.air_flow >= 0) {
                      this.rect_velocity = this.air_flow/(this.rectArea()/116);
                  }
      
                  if (this.air_flow >= 1500) {
                      this.rect_velocity = this.air_flow/(this.rectArea()/116);
                  }
      
                  if (this.air_flow >= 2000) {
                    this.rect_velocity = this.air_flow/(this.rectArea()/116);
                  }
      
                  if (this.air_flow >= 2500) {
                    this.rect_velocity = this.air_flow/(this.rectArea()/116);
                  }
      
                  if (this.air_flow >= 3000) {
                    this.rect_velocity = this.air_flow/(this.rectArea()/116);
                  }
                  return;
              }
              if (this.velocity && this.diameter) {
                  this.air_flow = this.velocity * (this.area()/rough);
                  this.pressure_loss = 0.109136 * Math.pow(this.air_flow, 1.9) / Math.pow(this.diameter, 5.02);
                  // IMPORTANTTTT!!!!!!!!!!!!! the decimal going into width in the calculation was changed from .6 to .65
                  this.rect_pressure_loss = Math.pow(((this.pressure_loss * Math.pow(1/(this.width/0.65 * (this.width * this.height) / (this.width + this.height)), 5.02))/0.059136), (1/1.9));

                  
                  if (this.air_flow >= 0) {
                    this.rect_velocity = this.air_flow/(this.rectArea()/116);
                  }
      
                  if (this.air_flow >= 1500) {
                      this.rect_velocity = this.air_flow/(this.rectArea()/116);
                  }
      
                  if (this.air_flow >= 2000) {
                    this.rect_velocity = this.air_flow/(this.rectArea()/116);
                  }
      
                  if (this.air_flow >= 2500) {
                    this.rect_velocity = this.air_flow/(this.rectArea()/116);
                  }
      
                  if (this.air_flow >= 3000) {
                    this.rect_velocity = this.air_flow/(this.rectArea()/116);
                  }
                  
                  return;
              }
          }
          
          format_properties() {
              this.air_flow = this.air_flow && Math.round(this.air_flow * 10) / 10;
              this.pressure_loss = this.pressure_loss && Math.round(this.pressure_loss * 1000) / 1000;
              this.velocity = this.velocity && Math.round(this.velocity * 10) / 10;
              this.diameter = this.diameter && Math.round(this.diameter * 10) / 10;
              this.rect_pressure_loss = this.rect_pressure_loss
          }
    }


    if (calculation === "friction") {
      let ductFriction = new Duct(airFlow, pressure, null, null, width, height, null, null)

      console.log(height)
      console.log(ductFriction.pressure_loss)

      setAirFlow(ductFriction.air_flow)
      setPressure(ductFriction.pressure_loss)
      setRoundVelocity(Math.round(ductFriction.velocity))

      
      setDiameter(Math.round(ductFriction.diameter))



      const radius = Math.round(ductFriction.diameter) / 2;
      const circleArea = Math.PI * Math.pow(radius, 2);
      setHeight(Math.round(circleArea / width * 10)/10)
    }

    if (calculation === "velocity") {
      let ductVelocity = new Duct(airFlow, null, velocity, null, width, null, null, null)
      
      let roundedPressure = parseFloat(ductVelocity.pressure_loss.toFixed(3))

      setPressure(roundedPressure)
      setDiameter(Math.round(ductVelocity.diameter))

      const radius = Math.round(ductVelocity.diameter) / 2;
      const circleArea = Math.PI * Math.pow(radius, 2);
      setHeight(Math.round(circleArea / width * 10)/10)
      
    }




  }

  useEffect(() => {
    setrectangularVelocity(Math.round(airFlow/(width*height/rough)-150))
  }, [roundVelocity])

  return (
    
    loading == false ?
    <div className='home__container'>
      
      <div className='home__container__navbar'>
        <h1>
          ductulator.app
        </h1>

        <img src={logo} />
      </div>

      <div className='home__container__results__container'>
            <div className='home__container__size' >
                <h2>
                  <p>Calculation Method</p>
                </h2>


                <select value={calculation} onChange={(e) => setCalculation(e.target.value)} >
                  <option value={"friction"}>
                    Friction
                  </option>

                  <option value={"velocity"}>
                    Velocity
                  </option>

                </select>
                <br/>

            </div>

      </div>



      <div className='home__container__results__container'>

        <div className='home__container__results' >
            {
              calculation === "friction" ?
              <input  style={{ height: "32px", fontSize: "18px", marginBottom: "12px" }} type="number" value={pressure} onChange={(e) => setPressure(e.target.value)} />
              :
              <h1>
                {pressure}
              </h1>
            }

            <h2 style={{ textAlign: "center", marginTop: "12px" }} >
              Static Pressure Loss [inW/100ft]
            </h2>
        </div>


        <div className='home__container__results' >
            <input style={{ height: "32px", fontSize: "18px", width: "100%" }} type="number" value={airFlow} onChange={(e)=>setAirFlow(e.target.value)} />

            <br/>

            <h2>
            Air Flow [CFM]
            </h2>
        </div>

      </div>


      {
        calculation === "velocity" ?
        <div className='home__container__results__container'>
        
        <div className='home__container__results' >
            
            <input style={{ height: "32px", fontSize: "18px" }} type="number" value={velocity} onChange={(e)=>setVelocity(e.target.value)} />
            
            <br/>

            <h2>
            Rectangular Duct Air Velocity
            </h2>
        </div>

        <div className='home__container__results' >
            <input style={{ height: "32px", fontSize: "18px" }} type="number" value={velocity} onChange={(e)=>setVelocity(e.target.value)} />
            <br/>

            <h2>
              Round Duct Air Velocity
            </h2>
        </div>
      </div>
      :
      <div className='home__container__results__container'>
        <div className='home__container__results' >
            <h1>
            { rough == 140 ? roundVelocity - 100 : roundVelocity } FPM 
            </h1>
            <br/>

            <h2>
              Round Duct Air Velocity
            </h2>
        </div>


        <div className='home__container__results' >
            <h1>
            {rectangularVelocity} FPM
            </h1>
            <br/>

            <h2>
            Rectangular Duct Air Velocity
            </h2>
        </div>

      </div>
      }


      <div className='home__container__results__container'>

        
        <div className='home__container__size' >
        <h2>
          {
            rough == 144 &&
            <p>0.00049 Absolute Roughness [Foot]</p>
          } 
        </h2>

        <h2>
          {
            rough == 140 &&
            <p>0.005 Absolute Roughness [Foot]</p>
          } 
        </h2>





            <select value={rough} onChange={(e) => setRough(e.target.value)} >
              <option value={144} >
                Galvanized steel sheet (unknown specs)
              </option>

              <option value={140} >
                Fibrous glass duct liner, air side with facing material
              </option>



            </select>
            <br/>

        </div>

      </div>

      <div className='visuals'>

          <div class="vis__left">
            <span class="number">{diameter}</span>
          </div>

          <div className='vis__right_cont'>

            <div style={{ width: `${200}px`, height: `${100}px` }} className='vis__right'>
            <p className='ltwo'>
              {height}
            </p>

            <p className='lone'>
              {width}
            </p>
            </div>
          </div>


      </div>

      <div className='home__container__results__container'>


      {
          calculation === "dimensions" ?
          <div className='home__container__results size'>
            <input style={{ height: "32px", fontSize: "18px", width: "100%" }} type="number" value={diameter} onChange={(e)=>setDiameter(e.target.value)} />
            <br/>
            <h2>
            [Diameter Inches]
            </h2>
          </div>
          :
          <div className='home__container__results size' >
          <h2>{diameter} [Diameter Inches]</h2>
          </div>
        }


          <div className='home__container__results size'>
            <input style={{ height: "32px", fontSize: "18px", width: "100%" }} type="number" value={width} onChange={(e)=>setWidth(e.target.value)} />
            <br/>
            <h2>
              L1 [Inches]
            </h2>
          </div>



        <div className='home__container__results size'>
            <h2>{height}</h2>
            <br/>
            <h2>
              L2 [Inches]
            </h2>
        </div>
      </div>

      <button onClick={()=>ductulate()} className='duct__button'>
        Ductulate!
      </button>

      <div className='home__container__second'>
        <h2>
          All of your curb needs... <a target="_blank" href="https://curbco.com/products/" >click here to get started</a>
        </h2>
      </div>



    </div>
    :
    <div className='svgContainer'>
      <svg viewBox="0 0 100 100">
        <g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="6">

          <path d="M 21 40 V 59">
            <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            values="0 21 59; 180 21 59"
            dur="2s"
            repeatCount="indefinite" />
          </path>

          <path d="M 79 40 V 59">
            <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            values="0 79 59; -180 79 59"
            dur="2s"
            repeatCount="indefinite" />
          </path>

          <path d="M 50 21 V 40">
            <animate
            attributeName="d"
            values="M 50 21 V 40; M 50 59 V 40"
            dur="2s"
            repeatCount="indefinite" />
          </path>

          <path d="M 50 60 V 79">
            <animate
            attributeName="d"
            values="M 50 60 V 79; M 50 98 V 79"
            dur="2s"
            repeatCount="indefinite" />
          </path>

          <path d="M 50 21 L 79 40 L 50 60 L 21 40 Z">
          <animate
            attributeName="stroke"
            values="rgba(255,255,255,1); rgba(100,100,100,0)"
            dur="2s"
            repeatCount="indefinite" />
          </path>

          <path d="M 50 40 L 79 59 L 50 79 L 21 59 Z"/>
          
          <path d="M 50 59 L 79 78 L 50 98 L 21 78 Z">
          <animate
            attributeName="stroke"
            values="rgba(100,100,100,0); rgba(255,255,255,1)"
            dur="2s"
            repeatCount="indefinite" />
          </path>
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="translate"
            values="0 0; 0 -19"
            dur="2s"
            repeatCount="indefinite" />
        </g>
      </svg>
    </div>
  )
}

export default Home